import * as React from "react"

import { graphql, useStaticQuery } from 'gatsby'

import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay  } from 'swiper/core'
import BackgroundImage from 'gatsby-background-image'
import logoPutih from '../images/logo-putih-dibalik.png'
import { Fade } from "react-awesome-reveal"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faStar, faLocationDot, faClock } from '@fortawesome/free-solid-svg-icons'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

SwiperCore.use([Autoplay])

const IndexPage = () => {
	const [isOpen, setisOpen] = React.useState(false);
	function handleClick() {
		setisOpen(!isOpen);
	}
  	const data = useStaticQuery(
		graphql`
		  query {
			desktop: file(relativePath: { eq: "bg-cover.jpg" }) {
			  childImageSharp {
				fluid(quality: 99, maxWidth: 1920) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
		  }
		`
	  )
	
	const imageData = data.desktop.childImageSharp.fluid

	return (
		<>
			<Helmet>
					<title>Pusat Aki Jakarta - Layanan Aki 24 Jam Pesan Antar Aki Bergaransi</title>
					<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
			</Helmet>
			<div id='home' className="w-full bg-white overflow-hidden z-40">
				<div className="z-30 bg-primary drop-shadow">
					<div className="container mx-auto flex flex-col px-6 md:px-14">
						<div className="flex flex-row justify-between">
							<div className="logo flex items-center justify-center z-30 my-2">
								<img className="h-[80px] md:h-[95px]" height='100' placeholder="none" src={logoPutih} alt="logo"/>
							</div>
							<div className="hidden lg:flex lg:flex-row font-semibold">
								<AnchorLink to='/#home' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-white">
									Beranda
								</AnchorLink>
								<AnchorLink to='/#produk' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-white">
									Produk
								</AnchorLink>
								<AnchorLink to='/#testimoni' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-white">
									Testimoni
								</AnchorLink>
								<AnchorLink to='/#kontak' className="flex flex-row gap-x-1 nav-item p-2 m-1 items-center justify-center text-lg transition ease-in-out text-white">
									Kontak
								</AnchorLink>
							</div>
							<button type="button" className="block text-white lg:hidden" onClick={handleClick}>
								<svg className="h-6 w-6 fill-current border border-primary rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
									{isOpen && (
										<path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
									)}
									{!isOpen && (
										<path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
									)}
								</svg>
							</button>
						</div>
						<div className={`transition-all duration-150 text-white font-semibold lg:hidden ${  isOpen ? "max-h-screen visible" : "max-h-0 invisible" }`}>
							<AnchorLink to='/#home' className="flex flex-row gap-x-1 nav-item p-4 mt-4 justify-center border-y border-white">
								Home
							</AnchorLink>
							<AnchorLink to='/#produk' className="flex flex-row gap-x-2 nav-item p-4 mt-1 justify-center border-b border-white">
								Produk
							</AnchorLink>
							<AnchorLink to='/#testimonial' className="flex flex-row gap-x-2 nav-item p-4 mt-1 justify-center border-b border-white">
								Testimoni
							</AnchorLink>
							<AnchorLink to='/#kontak' className="flex flex-row gap-x-2 nav-item p-4 mt-1 justify-center border-b border-white">
								Kontak
							</AnchorLink>
						</div>
					</div>
				</div>

				<BackgroundImage
				className='hero'
				fluid={imageData}
				backgroundColor={`#000`}
				>
					<div className='flex flex-col justify-center items-center w-full h-full min-h-[650px]'>
						<h4 className="text-center text-md md:text-xl font-bold text-white mt-4">Aki Mobil Anda Susah Starter?</h4>
						<h4 className="text-center text-md md:text-xl font-bold text-white mx-8 mb-2">Jangan panik, hubungi layanan aki <span className="text-secondary italic">SHOP</span><span className="text-[#0178d7] italic">DRIVE</span> 24 Jam<br/>Solusi Aki Mobil Soak<br/>Gratis Ongkir, Gratis Pasang</h4>
						<h1 className='text-2xl md:text-3xl font-bold text-secondary text-center'>PUSAT AKI JAKARTA</h1>
						<h4 className="text-white mt-2 mb-1">HOME DELIVERY SERVICE</h4>
						<div className="border-b border-white p-1 w-80"/>
						<h2 className="text-2xl mt-2 font-bold text-white">AKI 24 JAM</h2>
						<div className='whatsapp-button m-2 mt-8'>
							<a href="https://api.whatsapp.com/send?phone=6281388885156&text=Halo%2C pusatakijakarta.com" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-700 hover:bg-green-600 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center shadow-md w-[265px]">
							<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
							<span>Whatsapp 081388885156</span>
							</a>
						</div>
						<div className='phone-button m-2'>
							<a href='tel:+6281388885156' className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-red-600 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center shadow-md w-[265px]">
							<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={270}/>
							<span>Telepon 081388885156</span>
							</a>
						</div>
					</div>
				</BackgroundImage>

        		<div className="py-6">
					<div className="container my-10 mx-auto px-2">
						<h2 className="text-[#002060] text-3xl md:text-4xl font-bold text-center my-6">Aki Mobil Anda Ngadat?</h2>
						<div className='px-2 flex flex-col justify-center'>
							<div className="text-center mx-auto">
								<StaticImage src="../images/warranty.png" alt="delivery" height={280} placeholder="none"/>
								<p className="my-8 text-lg"><strong>Pusat <span className="text-red-600">Aki </span><span className="text-primary">Jakarta</span></strong> merupakan layanan aki 24 jam antar aki bergaransi yang sudah berpengalaman di dukung dengan teknisi yang akan memberikan solusi atas aki mobil anda yang bermasalah.</p>
								<p className="text-lg my-6">
								Di PUSAT AKI JAKARTA tersedia produk-produk aki dengan kualitas bergaransi dari berbagai brand. Di Kami tersedia brand-brand OE dari <span className="font-bold text-green-800">GS Astra</span>, <span className="font-bold text-yellow-600">Incoe</span>, <span className="font-bold text-red-600">Yuasa</span>, <span className="font-bold text-blue-700">Furukawa Battery</span> maupun brand Import seperti <span className="font-bold text-teal-500">Varta</span>, <span className="font-bold text-lime-500">Amaron</span>, <span className="font-bold text-cyan-500">Panasonic</span>, <span className="font-bold text-indigo-500">Bosch</span>, <span className="font-bold text-rose-500">Solite</span>, <span className="font-bold text-amber-500">Faster</span>, <span className="font-bold text-orange-500">Delkor</span> dan lain-lain.
								&nbsp;<b>Pusat<span className="text-red-600">Aki</span><span className="text-primary">Jakarta</span>.com</b> adalah jawaban untuk toko aki mobil terdekat Anda, dan kami melayani Free Pemasangan dan Pengantaran ke rumah maupun tempat anda mengalami kendala aki kendaraan anda.
								Kami adalah Pusat Aki mobil murah, berkualitas, bergaransi & terlengkap.
								</p>
								<h3 className="text-[#002060] text-xl md:text-2xl font-bold text-center my-6">Hubungi Layanan Aki 24 Jam Pasang Antar Aki Gratis Bergaransi</h3>
								<div className="w-full md:w-1/2 grid grid-cols-1 md:grid-cols-2 mx-auto my-6 gap-4 md:gap-6">
									<a className='zoom-in-out-box border-2 border-black rounded p-6' href="https://api.whatsapp.com/send?phone=6281388885156&text=Halo%2C pusatakijakarta.com" target="_blank" rel="noreferrer">
										<StaticImage src="../images/whatsapp.png" alt="delivery" height={96} placeholder="none"/>
										<p className="text-xl font-semibold text-secondary">081388885156</p>
									</a>
									<a className='zoom-in-out-box border-2 border-black rounded p-6' href='tel:+6281388885156'>
										<StaticImage src="../images/telepon.png" alt="delivery" height={96} placeholder="none"/>
										<p className="text-xl font-semibold text-secondary">081388885156</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id='layanan' className='w-full flex flex-col bg-main pb-6'>
					<div className='container mx-auto'>
						<div className='flex justify-center my-10'>
							<h2 className='text-3xl md:text-4xl font-bold text-center text-[#002060]'>Layanan Aki 24 Jam Kami</h2>
						</div>
						<div className='grid grid-cols-1 md:grid-cols-3'>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-2 m-4 rounded-lg border'>
									<div className='image mx-auto'>
										<StaticImage src="../images/servic.png" alt="service" placeholder="none"/>
									</div>
								</div>
							</Fade>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-2 m-4 rounded-lg border'>
									<div className='image mx-auto'>
										<StaticImage src="../images/deliver.png" alt="delivery" placeholder="none"/>
									</div>
								</div>
							</Fade>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-2 m-4 rounded-lg border'>
									<div className='image mx-auto'>
										<StaticImage src="../images/warrant.jpg" alt="warranty" placeholder="none"/>
									</div>
								</div>
							</Fade>
						</div>
					</div>
				</div>
					
				<div className='bg-gray-200 py-8'>
					<div className='container mx-auto px-2 text-center'>
						<div id='produk' className='flex flex-col'>
							<div className='flex justify-center mt-8'>
								<h2 className='text-3xl md:text-4xl font-bold text-center text-[#002060]'>Produk</h2>
							</div>
						</div>
						<div className=' grid grid-cols-2 md:grid-cols-3 gap-4 my-8'>
							<StaticImage src="../images/gs.png" alt="produk" loading='{ lazy }'/>
							<StaticImage src="../images/amaron.png" alt="produk" loading='{ lazy }'/>
							<StaticImage src="../images/yuasa1.png" alt="produk" loading='{ lazy }'/>
							<StaticImage src="../images/incoe.png" alt="produk" loading='{ lazy }'/>
							<StaticImage src="../images/atlas.png" alt="produk" loading='{ lazy }'/>
							<StaticImage src="../images/xtra.png" alt="produk" loading='{ lazy }'/>		
						</div>
					</div>
				</div>

				<div className='bg-gray-100 py-8'>
					<div className='container mx-auto text-center px-8 my-8'>
						<div id='testimoni' className='flex flex-col mb-8'>
							<div className='flex justify-center'>
								<h2 className='text-3xl md:text-4xl font-bold text-center text-[#002060]'>Testimoni</h2>
							</div>
						</div>
						<Swiper
						slidesPerView={1}
						centeredSlides={false} 
						autoplay={{
							"delay": 4000,
							"disableOnInteraction": false
						}} 
						breakpoints={{
							1024: {
								slidesPerView: 3,
							}
						}}
						>
							<SwiperSlide>
								<div className='flex flex-col w-full lg:w-5/6 mx-auto bg-white px-6 pb-10 rounded shadow-sm h-60'>
									<p className='text-lg mt-16 mx-5 text-center'>Melayani dengan hati, ramah, mantaplah....</p>
									<div className="rating text-center mt-2 text-primary">
										<FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
									</div>
									<p className="mt-2 mx-5 text-center"><b>Heru</b></p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-full lg:w-5/6 mx-auto bg-white px-6 pb-10 rounded shadow-sm h-60'>
									<p className='text-lg mt-16 mx-5 text-center'>Aki nya di garansi Satu Tahun... wooww Okelahh..</p>
									<div className="rating text-center mt-2 text-primary">
										<FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
									</div>
									<p className="mt-3 mx-5 text-center"><b>Eko</b></p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-full lg:w-5/6 mx-auto bg-white px-6 pb-10 rounded shadow-sm h-60'>
									<p className='text-lg mt-16 mx-5 text-center'>Respon sangat cepat....</p>
									<div className="rating text-center mt-2 text-primary">
										<FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
									</div>
									<p className="mt-3 mx-5 text-center"><b>Farid</b></p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
				
				
				<div id="kontak" className='bg-gray-200 md:px-10 md:py-8 py-8'>
					<div className='flex flex-col items-center justify-center'>
						<div className='w-11/12 md:w-2/4 mb-2 flex flex-col justify-center'>
							<div className='flex flex-col justify-center mb-8'>
								<h2 className='text-3xl md:text-5xl font-bold text-center text-[#002060]'>Pesan Aki Mobil<br/>Sekarang Juga</h2>
								<h2 className='text-2xl md:text-4xl font-bold text-center text-red-600 mt-4'>Hubungi Kami</h2>
							</div>
							<div id='hubungi' className='flex flex-col'>
								<a href='tel:+6281388885156'>
									<div className='zoom-in-out-box phone text-center text-lg mb-8'>
										<div className="flex flex-col items-center justify-center">
											<div className="flex items-center justify-center">
												<div className="bottom-call bg-red-600 border border-red-600 p-6 rounded-full text-white">
													<FontAwesomeIcon icon={faPhone} size="3x" />
												</div>
											</div>
											<div className="mt-4 text-3xl md:text-2xl"><b>Call Now : <span className="text-2xl">081388885156</span></b></div>
										</div>
									</div>
								</a>
								<a href="https://api.whatsapp.com/send?phone=6281388885156&text=Halo%2C pusatakijakarta.com" target="_blank" rel="noreferrer">
								<div className='zoom-in-out-box phone text-center text-lg mb-4'>
										<div className="flex flex-col items-center justify-center">
											<div className="flex items-center justify-center">
												<div className="bottom-call bg-green-700 border border-green-700 p-6 rounded-full text-white">
													<FontAwesomeIcon icon={faWhatsapp} size="3x" />
												</div>
											</div>
											<div className="mt-4 text-3xl md:text-2xl"><b>Chat Now : <span className="text-2xl">081388885156</span></b></div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="mx-auto text-center font-semibold pb-6">
					<div className="my-10 px-8">
						<h2 className="text-xl text-red-600 italic">Quote :</h2>
						<p className="text-lg italic">Ketika kamu Jatuh<br/>Ingatlah selalu ketiga orang ini :<br/>1. Siapa yang mendorong<br/>2. Siapa yang membantumu berdiri<br/>3. Dan siapa yang hanya diam, melihat kamu jatuh<br/>Karena semuanya itu akan berguna, ketika kamu bisa berjalan kembali</p>
					</div>
				</div>

				<div id="kontak" className='bg-primary md:px-10 md:py-10 py-8 px-6 text-white'>
					<div className='flex flex-col items-center justify-center container mx-auto'>
						<div className='grid grid-rows-1 grid-cols-1  md:grid-cols-4 gap-8'>
							<div className="flex flex-col">
								<div className="text-3xl font-bold mb-4"><h2>Tentang Kami</h2></div>
								<div className="mb-4">
									<StaticImage height={128} placeholder="none" src="../images/logo.png" alt="logo" />
								</div>
								<div className="mb-4">
									<p>Pusatakijakarta.com Toko aki mobil yang menjual berbagai merek aki mobil dengan gratis ongkir dan pemasangan di wilayah Jakarta dan sekitarnya</p>
								</div>
								<div className="social-media"></div>
							</div>
							<div className="flex flex-col">
								<div className="text-3xl font-bold mb-4"><h2>Menu</h2></div>
								<div className="mb-4">
									<AnchorLink to='/#home' className="flex font-medium flex-row nav-item py-1 text-lg transition ease-in-out">
										Beranda
									</AnchorLink>
									<AnchorLink to='/#produk' className="flex font-medium flex-row nav-item py-1 text-lg transition ease-in-out">
										Produk
									</AnchorLink>
									<AnchorLink to='/#testimoni' className="flex font-medium flex-row nav-item py-1 text-lg transition ease-in-out">
										Testimoni
									</AnchorLink>
									<AnchorLink to='/#kontak' className="flex font-medium flex-row nav-item py-1 text-lg transition ease-in-out">
										Kontak
									</AnchorLink>
								</div>
							</div>
							<div className="flex flex-col">
								<div className="text-3xl font-bold mb-4"><h2>Info Kontak</h2></div>
								<div className="mb-4">
									<ul>
										<li className="py-1"><FontAwesomeIcon className="w-4 h-4" icon={faLocationDot}/> <span className="ml-3 text-md font-medium">Jakarta</span></li>
										<li className="py-1"><FontAwesomeIcon className="w-4 h-4" icon={faPhone}/> <span className="ml-3 text-md font-medium">+6281388885156</span></li>
										<li className="py-1"><FontAwesomeIcon className="w-4 h-4" icon={faClock}/> <span className="ml-3 text-md font-medium">Available 24 Jam</span></li>
									</ul>
								</div>
							</div>
							<div className="flex flex-col">
								<div className="text-3xl font-bold mb-4"><h2>Gratis Konsultasi</h2></div>
								<div className="mb-4">
									<div className='whatsapp-button my-2'>
										<a href="https://api.whatsapp.com/send?phone=6281388885156&text=Halo%2C pusatakijakarta.com" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-700 hover:bg-green-600 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center shadow-md w-[265px]">
										<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
										<span>Whatsapp 081388885156</span>
										</a>
									</div>
									<div className='phone-button my-2'>
										<a href='tel:+6281388885156' className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-red-600 text-white font-bold py-3 px-4 rounded inline-flex items-center justify-center shadow-md w-[265px]">
										<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={270}/>
										<span>Telepon 081388885156</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="float drop-shadow">
					<a href="https://api.whatsapp.com/send?phone=6281388885156&text=Halo%2C pusatakijakarta.com" target="_blank" rel="noreferrer" className="">
						<StaticImage height={56} placeholder="none" src="../images/wa-button-float.png" alt="whatsapp"/>
					</a>
				</div>

				<div className='footer bg-black text-center text-sm text-white'>
					<p className='py-3'>&copy; 2022 <a href="https://www.pusatakijakarta.com">PusatAkiJakarta.com</a> All Right Reserved<br/></p>
				</div>
			</div>
		</>
  )
}

export default IndexPage
